<template>
  <div>
    <b-col cols="12">
      <h2>Подозрительные события</h2>
    </b-col>
    <b-overlay :show="loading">
      <b-col cols="12" class="d-flex">
        <b-card class="w-100 mb-4">
          <b-table :items="eventsItems" :fields="fields" :small="true">
            <template #cell(flight)="data">
              {{data.item.trip_id}}<br>{{data.item.route}}<br>{{time(data.item.start_time)}}<br><b>{{data.item.carrier}}</b>
            </template>
            <template #cell(drivers)="data">
              {{data.item.first_driver}}<br>{{data.item.second_driver}}
            </template>
            <template #cell(handled)="data">
              <div class="text-center">
                <b-icon v-if="data.item.confirmed" class="action-icon" icon="check-lg" variant="success" font-scale="1.2" title="Обработано"></b-icon>
                <b-icon v-else class="action-icon" icon="x-lg" variant="danger" title="Не обработано" @click="handle(data.item.id)"></b-icon>
              </div>
            </template>
          </b-table>
          <b-pagination-nav
              :link-gen="linkGen"
              :value="page"
              @page-click="load"
              :number-of-pages="maxPages"
              use-router
          ></b-pagination-nav>
        </b-card>
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";
import eventsApi from "@/api/events.api";

export default {
  name: "SuspiciousEvents",
  data() {
    return {
      currentPage: 1,
      errors: {},
      fields: [
        {
          key: 'id',
          label: "ID"
        },
        {
          key: 'event_type',
          label: "Событие"
        },
        {
          key: 'description',
          label: "Описание"
        },
        {
          key: 'flight',
          label: 'Рейс'
        },
        {
          key: 'drivers',
          label: "Водители"
        },
        {
          key: 'order_id',
          label: "Заказ"
        },
        {
          key: 'user',
          label: "Сотрудник"
        },
        {
          key: 'handled',
          label: "Обработано"
        }
      ]
    }
  },
  mounted() {
    this.$store.dispatch('Events/load', { page: 1 })
  },
  methods: {
    async handle(id) {
      if (confirm('Пометить событие как обработанное?')) {
        await eventsApi.confirmEvent(id)
        await this.$store.dispatch('Events/load')
      }
    },
    time(time) {
      return moment(time).format('DD.MM.YYYY HH:mm:ss')
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    load(event, page) {
      event.preventDefault()
      this.$store.dispatch('Events/load', { page })
    },
  },
  computed: {
    ...mapState({
      loading: state => state.Events.loading,
      eventsItems: state => state.Events.items,
      page: state => state.Events.page,
      maxPages: state => state.Events.maxPages
    })
  }
}
</script>

<style scoped>

</style>